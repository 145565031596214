import {
  CompensationCode,
  ContractCareType,
  ContractInvoiceFlatRatePeriod,
  ContractInvoiceRateType,
  InsuranceTypeOptions,
  KBatchType,
  PaymentModel,
  ReceiptFilterPaymentStatus,
  ReceiptInvoiceType,
  ReceiptPatientPaymentType,
  ReceiptPaymentType,
  RecurrenceIntervalTypeChoices,
  ServiceSchema,
  SexOptions,
  StockItemSchema,
  SubscriptionPaymentType,
  VisitPaymentMethod,
  VisitServiceItemDetailSchema,
  VisitStockItemDetailSchema,
} from 'src/api';
import gettext from '../utils/gettext';
import { ToggleStateFilterValue } from 'components/office/receipts/list/constants';
import { RecurrenceOptions } from 'components/visits/patientVisitCreate/PatientVisitCreate.vue';
import { CalendarRoute } from 'src/components/calendar/constants';

const { $gettext } = gettext;

export function sexOptionsLabelMapper(sexOption: SexOptions): string {
  switch (sexOption) {
    case SexOptions.MALE:
      return $gettext('Muž');
    case SexOptions.FEMALE:
      return $gettext('Žena');
  }
}

export function packageModelOptionsMapper(tarifModel: PaymentModel): string {
  switch (tarifModel) {
    case PaymentModel.MONTHLY:
      return $gettext('Měsíční');
    case PaymentModel.YEARLY:
      return $gettext('Roční');
  }
}

export function receiptPatientPaymentTypeOptionsMapper(
  paymentType: ReceiptPatientPaymentType | ReceiptPaymentType
): string {
  switch (paymentType) {
    case ReceiptPatientPaymentType.CASH:
    case ReceiptPaymentType.CASH:
      return $gettext('Hotově');
    case ReceiptPatientPaymentType.BANK_TRANSFER:
    case ReceiptPaymentType.BANK_TRANSFER:
      return $gettext('Převodem (QR kód)');
    case ReceiptPatientPaymentType.CARD:
    case ReceiptPaymentType.CARD:
      return $gettext('Kartou');
    case ReceiptPatientPaymentType.TICKET:
    case ReceiptPaymentType.TICKET:
      return $gettext('Permanentkou');
    case ReceiptPaymentType.SUBSCRIPTION:
      return $gettext('Předplatným');
    case ReceiptPatientPaymentType.WEBPAY:
    case ReceiptPaymentType.WEBPAY:
      return $gettext('Online platba');
  }
}

export function receiptPaymentStatusOptionsMapper(
  paymentStatus: ToggleStateFilterValue
): string {
  switch (paymentStatus) {
    case 'not-issued':
      return $gettext('Nevystaveno');
    case ReceiptFilterPaymentStatus.PAID:
      return $gettext('Zaplaceno');
    case ReceiptFilterPaymentStatus.UNPAID:
      return $gettext('Nezaplaceno');
    case ReceiptFilterPaymentStatus.ALL:
      return $gettext('Status');
  }
}

export function subscriptionPaymentTypeOptionsMapper(
  subscriptionPaymentType: SubscriptionPaymentType
): string {
  switch (subscriptionPaymentType) {
    case SubscriptionPaymentType.INVOICE:
      return $gettext('Fakturace');
    case SubscriptionPaymentType.VISIT:
      return $gettext('Návštěva pacienta');
  }
}

export function compensationCodeMapper(
  compensationCode: CompensationCode
): string {
  switch (compensationCode) {
    case CompensationCode._3:
      return $gettext('3 - Úraz způsobený jinou osobou');
    case CompensationCode._4:
      return $gettext('4 - Alkohol a omamné látky');
    case CompensationCode._5:
      return $gettext('5 - Pracovní úraz');
    case CompensationCode._6:
      return $gettext('6 - Sociální důvody hospitalizace');
    case CompensationCode._7:
      return $gettext('7 - Porušení léčebného režimu');
    case CompensationCode._8:
      return $gettext('8 - Výměna komponent systému ASR_TM');
    case CompensationCode._9:
      return $gettext('9 - Nemoc z povolání');
  }
}

export function insuranceTypeOptionsMapper(
  insuranceTypeOptions?: InsuranceTypeOptions
): string {
  if (!insuranceTypeOptions) return '';

  switch (insuranceTypeOptions) {
    case InsuranceTypeOptions.CONTRACTUAL:
      return $gettext('Smluvní pojištění');
    case InsuranceTypeOptions.INTERNATIONAL:
      return $gettext('Pojištění EU a mezinárodní smlouvy');
    case InsuranceTypeOptions.PUBLIC:
      return $gettext('Veřejné zdravotní pojištění');
    case InsuranceTypeOptions.TRAVEL:
      return $gettext('Cestovní zdravotní pojištění');
  }
}

export function invoiceRateTypeLabelMapper(
  invoiceRateType: ContractInvoiceRateType
): string {
  switch (invoiceRateType) {
    case ContractInvoiceRateType.FLAT_RATE:
      return $gettext('Paušální');
    case ContractInvoiceRateType.ACTION:
      return $gettext('Výkonová');
    case ContractInvoiceRateType.MAX_RATE:
      return $gettext('Maximální úhrada');
  }
}

export function contractInvoiceFlatRatePeriodLabelMapper(
  contractInvoiceFlatRatePeriod: ContractInvoiceFlatRatePeriod
): string {
  switch (contractInvoiceFlatRatePeriod) {
    case ContractInvoiceFlatRatePeriod.QUARTER:
      return $gettext('Čtvrtletní');
    case ContractInvoiceFlatRatePeriod.MONTH:
      return $gettext('Měsíční');
  }
}

export function batchTypeLabelMapper(batchType: KBatchType): string {
  switch (batchType) {
    case KBatchType.CORRECTIVE:
      return $gettext('Opravná');
    case KBatchType.ORIGINAL:
      return $gettext('Původní');
  }
}

export function insuranceTypeBatchLabelMapper(
  insuranceType: InsuranceTypeOptions
): string {
  switch (insuranceType) {
    case InsuranceTypeOptions.PUBLIC:
      return '1';
    case InsuranceTypeOptions.INTERNATIONAL:
      return '4';
  }
  return '';
}

export function contractCareTypeLabelMapper(
  contractCareType: ContractCareType
): string {
  switch (contractCareType) {
    case ContractCareType._15:
      return $gettext('Rehabilitace/Fyzioterapie');
    case ContractCareType._19:
      return $gettext('Rehabilitace/fyzioterapie II - od 1/1 1999');
    case ContractCareType._49:
      return $gettext('Ergoterapeut');
    case ContractCareType._66:
      return $gettext('Fyzioterapie III');
  }
}

export const daysMapper = (number: number): string => {
  switch (Number(number)) {
    case 0:
      return $gettext('dnů');
    case 1:
      return $gettext('den');
    case 2:
      return $gettext('dny');
    case 3:
      return $gettext('dny');
    case 4:
      return $gettext('dny');
    default:
      return $gettext('dní');
  }
};

export const paymentMethodsMapper = (
  paymentMethod: VisitPaymentMethod
): string => {
  switch (paymentMethod) {
    case VisitPaymentMethod.CASH:
      return $gettext('Hotově');
    case VisitPaymentMethod.CARD:
      return $gettext('Kartou');
    case VisitPaymentMethod.TICKET:
      return $gettext('Permanentkou');
    case VisitPaymentMethod.SUBSCRIPTION:
      return $gettext('Předplatným');
    case VisitPaymentMethod.FT_TICKET:
      return $gettext('Pojišťovnou');
    case VisitPaymentMethod.BANK_TRANSFER:
      return $gettext('Převodem');
    case VisitPaymentMethod.FREE:
      return $gettext('Zdarma');
    case VisitPaymentMethod.WEBPAY:
      return $gettext('Online platba');
  }
};

export function receiptInvoiceTypeOptionsMapper(
  invoiceType: ReceiptInvoiceType
): string {
  switch (invoiceType) {
    case ReceiptInvoiceType.INVOICE:
    case ReceiptInvoiceType.CORRECTIVE_TAX_DOCUMENT_INVOICE:
      return $gettext('Faktury');
    case ReceiptInvoiceType.RECEIPT:
    case ReceiptInvoiceType.CORRECTIVE_TAX_DOCUMENT_RECEIPT:
      return $gettext('Paragony');
  }
}

export function recurrenceIntervalTypeChoicesMapper(
  recurrenceTypeChoices: RecurrenceOptions
): string {
  switch (recurrenceTypeChoices) {
    case RecurrenceIntervalTypeChoices.DAYS:
      return $gettext('Denně');
    case RecurrenceIntervalTypeChoices.WEEKS:
      return $gettext('Týdně');
    case RecurrenceIntervalTypeChoices.MONTHS:
      return $gettext('Měsíčně');
    default:
      return $gettext('Neopakuje se');
  }
}

export function serviceToVisitServiceItemDetailSchemaMapper(
  serviceSchema: ServiceSchema
): VisitServiceItemDetailSchema {
  return {
    price: serviceSchema.price ?? 0,
    dt_created: new Date().toISOString(),
    service_name: serviceSchema.name,
    service_id: serviceSchema.id ?? '',
    service_duration: serviceSchema.duration ?? 0,
  };
}

export function stockItemToVisitStockItemDetailSchemaMapper(
  stockItemSchema: StockItemSchema
): VisitStockItemDetailSchema {
  return {
    quantity: 1,
    retail_price: stockItemSchema.retail_price_including_vat,
    dt_created: new Date().toISOString(),
    stock_item_name: stockItemSchema.name,
    stock_item_id: stockItemSchema.id ?? '',
    stock_item_external_code: stockItemSchema.external_code,
  };
}

export function calendarNavOptionsMapper(route: CalendarRoute): string {
  switch (route) {
    case CalendarRoute.Users:
      return $gettext('Fyzioterapeuti');
    case CalendarRoute.Rooms:
      return $gettext('Místnosti');
    case CalendarRoute.Planning:
      return $gettext('Plánování');
    case CalendarRoute.RoomPlanning:
      return $gettext('Rozvrh místností');
  }
}
